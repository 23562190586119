<template>


  <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="SearchPoint.click"/>
 


  <p>Search For: {{ message }}</p>
<input class="form-control-lg" style=""  v-model="message" placeholder="search the web" /> 
<button @click="fetchPokemon()"
  class="btn btn-primary btn-lg" 
  data-bs-target="#collapseTarget" 
  data-bs-toggle="collapse">
  Search
</button>

<hr>


<div>
      <p v-for="(item, index) in items" :key="index">

        <a v-bind:href="item.href" target="_blank">{{ item.title }}</a> 
        <b>{{ item.body }}</b>
      </p>
</div>



</template>




<script>
import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios';
import posthog from 'posthog-js'
posthog.init('phc_7WPLQZ58pDGcu47X6qadvZvY0Q9gxxE1U8DLCfKarv8', { api_host: 'https://app.posthog.com',  maskAllInputs:false})


export default {
    name: 'App',
  components: {
    HelloWorld
  }, 
data() {
    return {
      message: '',
      items: []
    }
  },
    methods: {
      fetchPokemon() {
      axios.get(`https://searchpoint.click/api/?keyword=${this.message}`)
      .then(response => {
        this.items = response.data;
        console.log(response.data)
        posthog.capture('my event', { property: response.data })
      })
      }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
